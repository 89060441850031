html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #0f1235;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
  background-color: #0f1235;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  /* padding: 20px; */
}

/* * {
  box-sizing: border-box;
} */

@media (max-width: 768px) {
  .App-header {
    padding: 10px;
  }

  .App-content {
    padding: 10px;
  }

  .team-logo {
    width: 32x;
    height: 32px;
  }

  .team-arrow {
    width: 16px;
    height: auto;
  }
}

.nba-guessr-logo {
  width: 50vw; /* 10% of the viewport width */
  height: auto;
}



.App-header {
  background-color: #020521;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-content {
  display: inline-block;
  background-color: #0f1235;
  font-size: calc(10px + 1vmin);
  color: #3b5ea9;
  padding: 50px 10px;
  width: 100%;
  flex-grow: 1;
}

footer {
  background-color: #0f1235;
  width: 100%;
  font-size: calc(4px + 0.6vmin);
  color: #3b5ea9;
  padding-top: 10px;
  text-decoration: none;
}

footer a {
  color: lightgray;;
  text-decoration: none;
  cursor: pointer;
}

footer a:hover {
  color: lightgray;
  text-decoration: underline;
  cursor: pointer;
}

.App-link {
  color: #3b5ea9;
}

h2 {
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.status-container {
  display: flex;
  border-radius: 5px; /* Optional: if you want rounded corners */
  padding: 10px;
  justify-content: space-around; /* This spreads out the child items evenly */
}

.status-item {
  text-align: center;
}

.status-title {
  font-size: calc(10px + 1vmin); /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 5px; /* Space between title and value */
  color: #3b5ea9;
}

.status-value {
  font-size: calc(15px + 2vmin); /* Adjust size as needed */
  font-weight: bold;
  color: white;
}

.alert-container {
  display: flex;
  border-radius: 5px; /* Optional: if you want rounded corners */
  padding: 10px;
  justify-content: space-around; /* This spreads out the child items evenly */
}

.alert-item {
  text-align: center;
}

.alert-title {
  font-size: calc(8px + 1vmin); /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 5px; /* Space between title and value */
  color: black;
}

.alert-value {
  font-size: calc(12px + 1vmin); /* Adjust size as needed */
  font-weight: bold;
  color: black;
}


.PlayerInfo {
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-self: center;
  font-size: calc(10px + 1vmin);;
}

.player-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.player-key {
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: center;
  margin-right: 5px;
}

.player-value {
  word-break: break-word;
  color: #ffffff;
}

.spoiler-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b5ea9;
  font-weight: 600;
  grid-column: 2;
  grid-row: 1;
  border-radius: 4px;
  padding: 4px 8px;
  z-index: 2;
}

.PerGameStats {
  display: grid;
  grid-template-columns: repeat(7, minmax(35px, 1fr));
  font-size: max(12px, calc(4px + 1vmin));
  gap: 5px;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
  align-self: center;
}

.TeamHistory {
  display: grid;
  grid-template-columns: repeat(4, minmax(40px, 1fr));
  font-size: max(12px, calc(4px + 1vmin));
  gap: 5px;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
  align-self: center;
}

.pg-column {
  display: flex;
  flex-direction: column;
  color: #ffffff
}

.pg-row, .pg-header{
  display: contents;
}

.cover-button {
  color: #3b5ea9;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  padding: 1px 10px 1px 10px;;
  /* border: 1px solid #ddd; */
  white-space: nowrap;
}

.cell {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  padding: 1px 10px 1px 10px;;
  /* border: 1px solid #ddd; */
  white-space: nowrap;
}

.pg-column .cell:nth-child(odd) {
  background-color: #0e1d49; /* Light grey for odd rows */
}

.pg-column .cell:nth-child(even) {
  background-color: #152851; /* Slightly darker grey for even rows */
}

.player-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.input-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.player-input {
  flex-grow: 1;
  margin-right: 10px;
  width: 100%;
  height: 40px;
  font-size: calc(4px + 1vmin);
}

.submit-button {
	background:#f47820;
	background-color:#f47820;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:calc(10px + 0.9vmin);
	font-weight:bold;
	padding:10px 20px;
	text-decoration:none;
}

.submit-button:hover {
	background:#f47820;
	background-color:#f47820;
}
.submit-button:active {
	position:relative;
	top:1px;
}

.tooltip {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  position: static;
  background-color: white;
  font-size: calc(6px + 0.9vmin);
  color: black;
  justify-content: center;
  align-self: center;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000; /* Ensure it sits above other content */
  width: 95%;
  top: 100%;
  left: 0;
  transform: none;
  cursor: pointer;
}

.tooltip li:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.highlighted {
  background-color: #f2f2f2;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: none;
  background-color: #0f1235;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #0f1235;
  font-size: calc(8px + 1vmin);
  font-weight: bold;
  color:#3b5ea9;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #69768e;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #69768e;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: none;
}

.tabcontent.show {
  display: block;
}

.team-history-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
}

.team-container {
  text-align: center;
  margin: 10px;
  border: 2px solid-black;
  display: inline-block;
  flex-wrap: wrap;
}

.team-logo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: solid #fff;
  box-sizing: border-box;
  object-fit: cover;
  background-color: #ffffff;
  margin: 0px 10px 0px 10px;
}

.team-season {
  font-size: calc(10px + 1vmin);;
}

.team-arrow {
  width: 50px;
  height: auto;
  margin: 0px 5px 0px 5px;
}